.text-gradient-1 {
  @supports (-webkit-background-clip: text) {
    background: linear-gradient(to right, #ff6e61, #acbbc1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.loading {
  padding-bottom: 56.25%;
  background: linear-gradient(-90deg, #eeeeee, #ffffff, #eeeeee);
  background-size: 300% 300%;
  animation: gradient 3s ease infinite;
}

.loading-bg {
  background: linear-gradient(-90deg, #eeeeee, #ffffff, #eeeeee);
  background-size: 300% 300%;
  animation: gradient 3s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
