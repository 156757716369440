//sass-lint:disable class-name-format
.legacyBrowser .object-cover {
  position: relative;
  overflow: hidden;

  img {
    width: 100% !important;
    height: auto !important;
  }
}

//sass-lint:enable class-name-format
