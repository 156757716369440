/* roboto-regular - latin */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* inter-regular - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/inter-v3-latin/inter-v3-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/inter-v3-latin/inter-v3-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/inter-v3-latin/inter-v3-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/inter-v3-latin/inter-v3-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/inter-v3-latin/inter-v3-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/inter-v3-latin/inter-v3-latin-regular.svg#Inter') format('svg'); /* Legacy iOS */
}

/* inter-700 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/inter-v3-latin/inter-v3-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/inter-v3-latin/inter-v3-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/inter-v3-latin/inter-v3-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/inter-v3-latin/inter-v3-latin-700.woff') format('woff'), /* Modern Browsers */
    url('../fonts/inter-v3-latin/inter-v3-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/inter-v3-latin/inter-v3-latin-700.svg#Inter') format('svg'); /* Legacy iOS */
}
