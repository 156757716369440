.text__content {
  p {
    margin-bottom: 1rem;
  }

  a {
    color: var(--primary);
    text-decoration: underline;

    &:hover {
      color: var(--primary-hover);
    }
  }

  ul,
  ol {
    margin-bottom: 1rem;
  }

  ul {
    list-style-type: disc;
    padding-left: 1rem;
  }

  ol {
    list-style-type: decimal;
    padding-left: 1.5rem;
  }

  h2, h3, h4 {
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  h3 {
    font-size: 1.375rem;
    font-weight: bold;
  }

  h4 {
    font-size: 1.25rem;
    text-decoration: underline;
  }
}
