.has-cookie-consent-banner {
  padding-bottom: 20rem;

  @media (min-width: 480px) {
    padding-bottom: 15rem;
  }

  @media (min-width: 768px) {
    padding-bottom: 12rem;
  }

  @media (min-width: 1024px) {
    padding-bottom: 9rem;
  }

  @media (min-width: 1280px) {
    padding-bottom: 6rem;
  }
}
