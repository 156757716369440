// Form CSS
form[name*='dynamic_form'] {
  @apply flex flex-wrap justify-start w-full;
  > div {
    @apply flex flex-wrap -mx-2 overflow-hidden;
  }

  .form-item {
    @apply my-2 px-2 overflow-hidden mt-4 relative;
    @media (min-width: $bp-md) {
      @apply mt-8;
    }

    &:first-child {
      @apply mt-0;
    }

    p {
      @apply text-xs text-secondary;
    }

    label {
      @apply block text-sm leading-5 font-medium text-secondary;

      p {
        @apply text-xs text-secondary my-2;
      }

      a {
        color: var(--primary);
        text-decoration: underline;

        &:hover {
          color: var(--primary-hover);
        }
      }
    }

    // Checkboxes
    label[for*='_checkbox'] {
      @apply block pl-8;

      width: 100%;
      @media (min-width: 1024px) {
        width: 75%;
      }

      + input[type='checkbox'] {
        @apply absolute left-0 ml-2 top-0 mt-1;
      }
    }

    // Different form sizes
    &.form-width-full {
      @apply w-full;
    }

    &.form-width-half {
      width: 100%;
      @media (min-width: $bp-md) {
        width: 50%;
      }
    }

    &.form-width-one-quarter {
      width: 100%;
      @media (min-width: $bp-md) {
        width: 25%;
      }
    }

    &.form-width-three-quarters {
      width: 100%;
      @media (min-width: $bp-md) {
        width: 75%;
      }
    }

    &.form-width-one-third {
      width: 100%;
      @media (min-width: $bp-md) {
        width: 34%;
      }
    }

    &.form-width-two-thirds {
      width: 100%;
      @media (min-width: $bp-md) {
        width: 66%;
      }
    }

    &.form-width-one-sixth {
      width: 100%;
      @media (min-width: $bp-md) {
        width: 16.6%;
      }
    }

    &.form-width-five-sixths {
      width: 100%;
      @media (min-width: $bp-md) {
        width: 83%;
      }
    }

    // Default Input Styles
    input[type='text'],
    input[type='email'],
    input[type='tel'],
    textarea {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: var(--light);
      border-color: var(--secondary);
      border-width: 1px;
      border-radius: .25rem;
      padding: .5rem .75rem;
      font-size: 1rem;
      line-height: 1.5;
      margin-top: .25rem;

      @apply w-full block px-4 py-3;

      &:focus {
        @apply outline-none;
      }
    }

    select {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e"); // scss-lint:disable quotes
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
      background-repeat: no-repeat;
      background-color: var(--light);
      border-color: var(--secondary);
      border-width: 1px;
      border-radius: .25rem;
      padding: .5rem 2.5rem .5rem .75rem;
      font-size: 1rem;
      line-height: 1.5;
      background-position: right .5rem center;
      background-size: 1.5em 1.5em;
      margin-top: .25rem;
      width: 100%;

      &::-ms-expand {
        color: #a0aec0;
        border: 0;

        @media not print {
          display: none;
        }
      }

      &:focus {
        @apply outline-none;
      }

      @media (min-width: $bp-sm) {
        @apply text-sm leading-5;
      }
    }


    button[type='submit'] {
      @apply relative min-w-button justify-center transition-colors duration-300 ease-in-out transform inline-flex items-center px-5 py-3 uppercase border border-transparent text-sm leading-none font-bold rounded-full text-white bg-secondary shadow-sm;

      &:hover {
        @apply bg-secondary-hover;
      }

      &:focus {
        @apply outline-none;
      }

      @media (min-width: $bp-lg) {
        @apply px-10 py-4 text-base;
      }
    }
  }

  #dynamic_form2_radioButtons {
    @apply flex justify-start items-center mt-1;

    input {
      @apply mr-2;
    }

    label {
      @apply mr-4;
    }
  }
}

.form-honung {
  display: none;
}
