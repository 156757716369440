:root {
  --burgerWidth: 25px;
  --burgerHeight: 25px;
  --burgerColor: var(--secondary);
  --burgerDotBg: var(--primary);
}


.burger {
  width: var(--burgerWidth);
  height: var(--burgerHeight);
  cursor: pointer;
  position: relative;

}

.burger,
.burger * {
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.bar {
  position: absolute;
  width: var(--burgerWidth);
  background-color: var(--burgerColor);
  height: 3px;
  margin: auto;
  left: 0;
  right: 0;
}

.bar:first-child {
  top: 3px;
  transition: all .4s .1s ease-in-out;
}

.bar:nth-child(2) {
  top: 0;
  bottom: 0;
  transition: all .4s ease-in-out;
}

.bar:last-child {
  bottom: 3px;
  transition: all .4s .2s ease-in-out;
}

.burger.active .bar:first-child {
  transform: translate(0, 8px) rotate(45deg);
  width: 70%;
  z-index: 2;
  background-color: var(--light);
}

.burger.active .bar:last-child {
  transform: translate(0, -8px) rotate(-45deg);
  width: 70%;
  z-index: 2;
  background-color: var(--light);
}

.burger.active .bar:nth-child(2) {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: var(--burgerDotBg);
  border-radius: 50%;
  z-index: 1;
}
