@media screen and (min-width: 1024px) {

  ul.mainnavigation li {
    display: inline-block;
  }

  ul.mainnavigation li a {
    position: relative;
    padding-bottom: 8px;
  }

  ul.mainnavigation li a::before,
  ul.mainnavigation li a::after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 15%;
    right: 15%;
    height: 2px;
    background-color: var(--primary);
  }

  ul.mainnavigation li a.active::before,
  ul.mainnavigation li a.active::after {
    background-color: var(--secondary);
  }

  ul.mainnavigation li a::before {
    opacity: 0;
    transform: translateY(-8px);
    transition: transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0s;
  }

  ul.mainnavigation li a::after {
    opacity: 0;
    transform: translateY(4px);
    transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s;
  }

  ul.mainnavigation li a:hover::before,
  ul.mainnavigation li a:hover::after,
  ul.mainnavigation li a:focus::before,
  ul.mainnavigation li a:focus::after,
  ul.mainnavigation li a.active::before,
  ul.mainnavigation li a.active::after {
    opacity: 1;
    transform: translateY(0);
  }

  ul.mainnavigation li a:hover::before,
  ul.mainnavigation li a:focus::before {
    transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s;
  }

  ul.mainnavigation li a:hover::after,
  ul.mainnavigation li a:focus::after {
    transition: transform 0s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0s 0.2s;
  }
}
