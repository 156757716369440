@tailwind base;
@tailwind components;
@tailwind utilities;

// Globals
@import 'variables';
@import 'fonts';
@import 'overrides';
@import 'ie11_fixes';

// 3rd Party Plugins
@import 'aos/src/sass/aos';
@import 'swiper/swiper-bundle';

// Components, Customizations
@import 'components/burger-menu-dot';
@import 'components/cookie-consent';
@import 'components/form';
@import 'components/gradients';
@import 'components/nav-underbar';
@import 'components/tables';
@import 'components/text-content';

@import 'plugins/swiper/swiper';
