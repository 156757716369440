table,
.table {
  width: 100%;
}

th {
  text-align: left;
}

thead,
tbody,
tfoot,
td, tr, th {
  padding: 0;
  margin: 0;
}

.table, table {
  + table,
  + .table {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid var(--secondary);
  }
}


tbody tr {
  > td:first-child {
    width: 50%;
    padding-right: 1rem;
  }

  td {
    hyphens: auto;
    width: auto;
    vertical-align: top;
    padding: .125rem;
  }

  &:nth-child(even) {
    background-color: #fafafa;
  }
}
