/** based on themes/proplas/tailwind.config.js  **/

:root {
  --primary: #e63323;
  --secondary: #233238;
  --tertiary-blue: #0580b5;
  --tertiary-green: #80ba27;
  --tertiary-yellow: #fbba02;
  --tertiary-petrol: #00b2c5;
  --primary-hover: #e57167;
  --secondary-hover: #939393;
  --light: #ffffff;
  --black: #000000;
  --image-background: #eeeeee;
  --burgerDotSize: 36px;
  --table-background: #f1f1f1;
}

$bp-sm: 640px;
$bp-md: 768px;
$bp-lg: 1024px;
$bp-xl: 1280px;
