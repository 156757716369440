/* Tablet */
@media screen and (min-width: 768px) {
  .swiper-heroteaser .swiper-button-prev {
    left: 45px !important;
  }
  .swiper-heroteaser .swiper-button-next {
    right: 75px !important;
  }
  .swiper-heroteaser .swiper-slide {
    max-width: 40%;
    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

/* Desktop */
@media screen and (min-width: 1280px) {

  .swiper-heroteaser .swiper-slide {
    max-width: 28%;
    margin-right: 3rem;
  }

  /* Box Shadow */
  .swiper-heroteaser .swiper-slide::after {
    /* Pre-render the bigger shadow, but hide it */
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 80%;
    margin: auto;
    opacity: 0;
    z-index: -1;
    box-shadow: 0 0 2rem 5px var(--black);
    transition: opacity 0.3s ease-in-out;
  }

  .swiper-heroteaser .swiper-slide:hover::after {
    opacity: 1;
  }
}
