/* ========== Other Swiper Types ========== */
@import 'swiper-heroteaser';

/*  ========== Swiper Default ========== */
.swiper-button-disabled {
  opacity: 0 !important;
}

.swiper-button-prev {
  left: 30px !important;
}

.swiper-button-next {
  right: 30px !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  color: var(--light);
}

.swiper-container {
  overflow: initial !important;
  overflow-x: hidden !important;
  overflow-y: visible !important;
}

.swiper-pagination {
  position: relative !important;
}

/* Desktop */
@media screen and (min-width: 1280px) {
  .swiper-button-prev {
    left: 60px !important;
  }
  .swiper-button-next {
    right: 60px !important;
  }
}

.swiper-pagination span {
  position: relative;
  width: 16px;
  height: 16px;
  margin: 3px;
  background: none !important;
  border: 2px solid var(--secondary);
  opacity: 0.5;
}

.swiper-pagination span.swiper-pagination-bullet-active {
  opacity: 1;
}

.swiper-pagination span::before {
  content: ' ';
  position: absolute;
  background-color: var(--secondary);
  width: 12px;
  height: 12px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scale(0);
  border-radius: 50%;
  transition: transform 0.3s, background-color 0.1s 0.2s ease-in-out;
}

.swiper-pagination span.swiper-pagination-bullet-active::before {
  transform: scale(0.7);
  background-color: var(--primary);
}
